import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Icon } from '@iconify/react';

export default function CButton(props) {
  return (
    <button
    type={props.type}
      style={{
        color: props.color ? props.color : "black",
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        fontFamily: props.fontFamily,
        marginTop: props.top,
        borderRadius: (props.borderRadius)?props.borderRadius:"5px",
        padding: props.padding,
        border: "none",
        transition: props.transition
      }}
      className={props.className}
      data-animation={props.dataAnimation}
      onClick={props.handleClick}
    >
      {props.text}
      {props.icon}
    </button>
  );
}
