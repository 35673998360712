import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Icon } from '@iconify/react';
import { useLocation } from "react-router-dom";
import NavLink from "./navLink";
import CLink from "./cLink";

export default function CNavBar(props) {
  const [active_link, setActiveLinks] = useState(1);
  const [navbarBackground, setNavbarBackground] = useState("transparent");
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (path === "/") {
      setActiveLinks(1);
    } else if (path === "/about") {
      setActiveLinks(2);
    } else if (path === "/tour_details/1") {
      setActiveLinks(3);
    } else if (path === "/contact") {
      setActiveLinks(4);
      handleScroll();  
    }

    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to handle scroll event
  const handleScroll = () => {
    const scrollY = window.scrollY;

    // Check scroll position and update Navbar background accordingly
    if (scrollY === 0) {
      setNavbarBackground("transparent");
    } else {
      setNavbarBackground("white");
    }
  };



  const links = [
    {
      id: 1,
      name: "Home",
      link: "/",
    },
    {
      id: 2,
      name: "About",
      link: "about",
    },
    // {
    //   id: 3,
    //   name: "Tour",
    //   link: "tour_details/1",
    // },
    {
      id: 4,
      name: "Contact Us",
      link: "contact",
    },
  ];


  return (
    <Navbar style={{zIndex: 10000}} collapseOnSelect expand="lg" className={(navbarBackground === "white")?"cNavSmall cShadowSmall whiteBg":"cNavSmall tranparentBg"} fixed="top">
      <Container>
        <Navbar.Brand className="bg-white px-2" style={{ borderRadius: "15px" }} href="/">
          <img
              style={{ width: "auto", height: "50px" }}
              src={require("../images/logo.png")}
              alt=""
            />
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto ">
            {links.map((link) => {
              return (
                (link.link != "contact")?<NavLink
                  keyValue={link.id}
                  link={link.link}
                  name={link.name}
                  // handleOclick={handleSetActiveLink(link.id)}
                  handleOclick={()=>setActiveLinks(link.id)}
                  className={
                    (link.id === active_link
                      ? " activeLink"
                      : " inactiveLink")
                  }
                  linkClassName={
                    (link.id === active_link
                      ? (navbarBackground === "transparent")?" activeLinkLink":" activeLinkLinkWhite"
                      : (navbarBackground === "transparent")?" inactiveLinkLink":" inactiveLinkLinkWhite")
                  }
                />:<li className={"px-5 mont"} key={link.id}>
                    <a
                      href="#contactForm"
                      oclick={()=>setActiveLinks(link.id)}
                      className={"cNavLinkSmall text-decoration-none" +
                      (link.id === active_link
                        ? " activeLink"
                        : " inactiveLink") + (link.id === active_link
                          ? (navbarBackground === "transparent")?" activeLinkLink":" activeLinkLinkWhite"
                          : (navbarBackground === "transparent")?" inactiveLinkLink":" inactiveLinkLinkWhite")
                    }>
                      {link.name}
                    </a>
                  </li>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
      <div className="d-none d-lg-flex me-5 justify-content-center align-items-center ">
          <div className="d-flex justify-content-center align-items-center">
              <CLink 
                  link="https://www.facebook.com/profile.php?id=100042811433198&mibextid=ZbWKw"
                  redirect={true}
                  element={<Icon icon="ic:round-facebook" color="#F3B324" fontSize={25} />}
              />
              <CLink 
                  link="https://twitter.com/Alathareetours"
                  redirect={true}
                  element={<Icon icon="basil:twitter-outline" color="#F3B324" className="mx-2" fontSize={25} />}
              />
              <CLink 
                  link="https://instagram.com/al_atharee_tours_official?igshid=OGQ5ZDc2ODk2ZA=="
                  redirect={true}
                  element={ <Icon icon="uil:instagram" color="#F3B324" fontSize={25} />}
              />
              <CLink 
                  link="https://www.linkedin.com/company/al-atharee-tours/"
                  redirect={true}
                  element={ <Icon className="ms-2" icon="jam:linkedin-square" color="#F3B324" fontSize={25} />}
              />
          </div>
      </div>
    </Navbar>
  );
}
