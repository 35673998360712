import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

export default function TourImagesSlider(props) {
  return (
    <div  id="carouselExampleControls2" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner tourSlider w-100" style={{height: "540px", }}>
            {
                props.images.map((image)=>{
                    return(
                        <div className={(image.id === 1)?" carousel-item active":"carousel-item"} style={{ height: "100%", width: "100%", 
                            backgroundImage: `url(${require("../images/"+image.image)})`, // Use require to get the correct path
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "14px"
                        }}>
                            
                        </div>
                    );
                })
            }
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
  );
}
