import React, { useState, useEffect } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.css";
import Ctext from "../components/text";
import Principle from "../components/principles";
import Heading from "../components/heading";
import CButton from "../components/cButtons";
import Footer from "../components/footer";
import Stat from "../components/stat";
import TeamCard from "../components/teamCard";
import { useParams } from 'react-router-dom';
import tours from "../utils";
import { Icon } from '@iconify/react';
import TourImagesSlider from "./tourImagesSlider";


export default function TourDetails(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [tour, setTour] = useState({});
  const { tour_id } = useParams();
  useEffect(() => {
    setIsLoading(false);
    console.log("this is the tour", tours)
    setTour(tours.find((tour) => tour.id === parseInt(tour_id)));
  }, []);

  console.log("this is the tour", tour)


  return !isLoading ? (
    <>
        <div className="container-fluid aboutBg" style={{height: "400px"}}>
            <div className="container d-flex align-items-center h-100">
              <div className="row w-100">
                <div className="col-12 col-md-6 d-flex justify-content-start align-items-center">
                    <Heading className="abril" color="white" fontFamily="Abril Fatface" text={tour.name} />
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-start justify-content-md-end align-items-center">
                    <div className="d-flex align-items-center">
                        <Icon className="me-2" icon="tdesign:money" color="#E8604C" fontSize={30} />
                        <div className="">
                            <Ctext fontSize="15px" className="p-0 m-0" fontWeight="bold" color="white" fontFamily="DM sans"  text="Cost" />
                            <Ctext fontSize="17px" className="p-0 m-0" color="white" fontFamily="DM sans"  text={tour.cost} />
                        </div>
                        <Icon className="ms-5 me-1" icon="tabler:clock" color="#E8604C" fontSize={30} />
                        <div className="">
                            <Ctext fontSize="15px" className="p-0 m-0" fontWeight="bold" color="white" fontFamily="DM sans"  text="Duration" />
                            <Ctext fontSize="17px" className="p-0 m-0" color="white" fontFamily="DM sans"  text={tour.duration} />
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
        <div className="bookBanner container-fluid py-1 bg-white" style={{position: "sticky", top: "108px", zIndex: 5, borderBottom: "2px solid #F0F3F6"}}>
          <div className="row mb-2">
              <div className="mt-2 col-12 col-md-6 d-flex justify-content-center">
                  <div className="d-flex align-items-center">
                    <Icon icon="ph:star" fontSize={25} className="me-2" color="#F3B324" />
                    <Icon icon="ph:star" fontSize={25} className="me-2" color="#F3B324" />
                    <Icon icon="ph:star" fontSize={25} className="me-2" color="#F3B324" />
                    <Icon icon="ph:star" fontSize={25} className="me-2" color="#F3B324" />
                    <Icon icon="ph:star" fontSize={25} className="me-2" color="#F3B324" />
                    <Ctext className="ms-1 mt-2" text="4.33" color="#9D9D9D" fontSize="17px"/>
                  </div>
              </div>
              <div className="mt-2 col-12 col-md-6  d-flex  justify-content-center">
                  <div>
                    <button type="button" style={{backgroundColor: "#F0F3F6"}} class="btn me-2"><Icon icon="ic:sharp-share" fontSize={25} className="me-2" color="#757783" /> Share</button>
                    <button type="button" style={{backgroundColor: "#E8604C"}} class="btn"><Icon icon="mdi:book-outline" fontSize={25} className="me-2" color="white" /> <span className="text-white">BOOK NOW</span></button>
                    {/* <button type="button" style={{backgroundColor: "#F0F3F6"}} class="btn"><Icon icon="ic:sharp-share" fontSize={25} className="me-2" color="#757783" /> Share</button> */}
                  </div>
              </div>
          </div>
        </div>

        {/* <div className=" mt-3 px-0 px-md-5 text-center"> */}
            <div className="row w-100 d-flex m-0 justify-content-center">
                <div className="col-12 col-sm-8 col-lg-5 p-0">
                    <TourImagesSlider images={tour.images} />
                    <Ctext fontSize="18px" color="#757783" fontFamily="DM sans" className="mt-2 px-3" text={tour.description} />
                    <Ctext fontSize="20px" fontWeight="bold" color="black" fontFamily="DM sans" className="mt-3 px-3" text="Include/Excludes" />
                    <div className="row w-100 px-3">
                      <div className="col-12 col-md-6">
                        {
                          tour.included.map((name)=>{
                            return <div className="d-flex">
                                    <Icon className="me-2" icon="mdi:done" color="green" fontSize={30} />
                                    <Ctext fontSize="18px" color="#757783" fontFamily="DM sans" className="mt-2" text={name} />
                                  </div>
                          })
                        }
                      </div>
                      <div className="col-12 col-md-6">
                        {
                          tour.included.map((name)=>{
                            return <div className="d-flex">
                                    <Icon className="me-2" icon="mdi:close" color="#F87777" fontSize={30} />
                                    <Ctext fontSize="18px" color="#757783" fontFamily="DM sans" className="mt-2" text={name} />
                                  </div>
                          })
                        }
                      </div>
                    </div>
                    <Ctext fontSize="20px" fontWeight="bold" color="black" fontFamily="DM sans" className="mt-3 px-3" text="Tour Plan" />
                    <div class="accordion mb-5 px-3" id="accordionExample">
                      {tour.plan.map((plan)=>{
                        return <div class="accordion-item">
                                <h2 class="accordion-header" id={"headingOne"+plan.id}>
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne"+plan.id} aria-expanded="true" aria-controls={"collapseOne"+plan.id}>
                                    {plan.day}
                                  </button>
                                </h2>
                                <div id={"collapseOne"+plan.id} class="accordion-collapse collapse show" aria-labelledby={"headingOne"+plan.id} data-bs-parent="#accordionExample">
                                  <div class="accordion-body">
                                    {plan.activity}
                                  </div>
                                </div>
                              </div>
                      })}
                    </div>
                </div>
            </div>
        {/* </div> */}
        
        <Footer/>
    </>
  ) : (
    <div className="divLoader">
      <div
        style={{ marginBottom: "200px", marginTop: "100px" }}
        className="lds-ripple"
      >
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
