import React, { Children } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

export default function 
CLink(props) {
  return (props.redirect === true)?(
    <a
        className={`text-decoration-none about_link ${props.className}`}
        target="_blank"
        href={props.link}
    >
        {props.element}
    </a>
  ):(
    <a
        className="text-decoration-none about_link"
        href={props.link}
        
    >
        {props.element}
    </a>
  );
}
