function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
}


const tours = [
  {
      id: 1,
      name: "MT. Elgon National Game park",
      location: "Western Uganda",
      cost: "UGX 200000",
      duration: "5 days",
      rating: 3.5,
      description: `Lorem ipsum available isn but the majority have suffered alteratin in some or form injected simply free text
      used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi
      architecto beatae vitae dicta sunt explicabo. Lorem ipsum is simply free text used by copytyping
      refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae
      vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros.`,
      included: ["Pick and Drop Service", "Break-fast and super per day"],
      excludes: ["Clothing", "Insuarance"],
      plan:[{id: 1, day: "Day 1", activity: `Lorem ipsum dolor sit amet, utinam munere antiopam vel ad. Qui eros iusto te. Nec ad feugiat
                      honestatis. Quo illum detraxit an. Ius eius quodsi molestiae at, nostrum definitiones his cu. Discere
                      referrentur mea id, an pri novum possim deterruisset.`},
            {id: 2, day: "Day 2", activity: `Minimal Activity. Alot will be dawah and staff`},
            {id: 3, day: "Day 3", activity: `we shall visit the best places in the world peopl`},
          ],
      review: [{"name": "Nassali Fatuma", "rating": 3.5, "comment": `This was the best trip i have ever been on! Is was some really amazing experience.
                      Thanks halaltours for you effort! Barak Allahu Fikum!`}],
      images: [{id: 1, image: "mont.png"},{id: 2, image: "gif1.jpg"},{id: 3, image: "gif2.jpg"}]
  },
  {
    id: 2,
    name: "Zanzibar Beaches",
    location: "Tanzania",
    cost: "UGX 200000",
    duration: "5 days",
    rating: 3.5,
    description: `Lorem ipsum available isn but the majority have suffered alteratin in some or form injected simply free text
    used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi
    architecto beatae vitae dicta sunt explicabo. Lorem ipsum is simply free text used by copytyping
    refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae
    vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros.`,
    included: ["Pick and Drop Service", "Break-fast and super per day"],
    excludes: ["Clothing", "Insuarance"],
    plan:[{"Day 1": `Lorem ipsum dolor sit amet, utinam munere antiopam vel ad. Qui eros iusto te. Nec ad feugiat
                    honestatis. Quo illum detraxit an. Ius eius quodsi molestiae at, nostrum definitiones his cu. Discere
                    referrentur mea id, an pri novum possim deterruisset.`},
          {"Day 2": `Minimal Activity. Alot will be dawah and staff`},
          {"Day 3": `we shall visit the best places in the world peopl`},
        ],
    review: [{"name": "Nassali Fatuma", "rating": 3.5, "comment": `This was the best trip i have ever been on! Is was some really amazing experience.
                    Thanks halaltours for you effort! Barak Allahu Fikum!`}],
    images: [{id: 1, image: "mont.png"},{id: 2, image: "gif1.jpg"},{id: 3, image: "gif2.png"}]
  },
];

export default tours;