// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Heading from "../components/heading";
// import { Autoplay, EffectFade } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import TestmonialCard from "./testimonialCard";

export default function TestmonialSlider() {
  return (
    <div className="row pt-0 pt-md-3 pb-5">
        <div className="col-12 col-lg-6">
            <TestmonialCard name="Maimuuna Hibatullah" image="maimuna.png" text="Al-Atharee Tours offered a fantastic Halal adventure, 
            ensuring a comfortable, Islamic-compliant environment. Despite minor issues, the experience was enjoyable. 
            Highly recommend for a respectful travel experience." />
        </div>
        <div className="col-12 col-lg-6">
            <TestmonialCard name="Dr. Musa J" image="musa.png" text="A round of applause for Al-Atharee Tours, 
            delivering an exceptional Halal travel experience. Their dedication to Islamic principles brought positivity 
            and joy to the journey, making it my top pick for an enriching and respectful escapade." />
        </div>
        <div className="col-12 col-lg-6">
            <TestmonialCard name="Abubakar" image="Abubakar.png" text="Hats off to Al-Atharee Tours for 
            an unforgettable Halal adventure. Their commitment to a warm, inclusive atmosphere made the journey
             delightful. I wholeheartedly recommend them for a truly enriching travel experience" />
        </div>
        <div className="col-12 col-lg-6">
            <TestmonialCard name="Nakamuli Fahiimah" image="nakamuli.png" text="Al tharee tours is the best so far, it's 
            in line with the Islamic principles which makes it different." />
        </div>
    </div>
  );
}
