import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

export default function Heading(props) {
  return (
    <h1
      style={{
        color: props.color ? props.color : "#414042",
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        // fontFamily: props.fontFamily,
        marginTop: props.top
      }}
      className={props.className}
      data-animation={props.dataAnimation}
    >
      {props.text}
    </h1>
  );
}
