// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Heading from "../components/heading";
// import { Autoplay, EffectFade } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import Ctext from "../components/text";

export default function TestmonialCard(props) {
  return (
    // <div style={{height: "200px"}} class="tooltip bg-danger">
    //     <p>his is due to their excellent service, competitive pricing an</p>
    //     <span class="triangle"></span>
    // </div>
    <div className="testimonialCard">
      <div className="cCard  m-md-5 p-md-5 cShadowSmall d-flex justify-content-center align-items-center">
          <Ctext fontSize="15px" className="mont mt-2" text={props.text}/>
      </div>
      <div className=" reviewerCardDiv position-absolute d-flex">
            <div style={{zIndex: 9, 
                backgroundImage: `url(${require("../images/"+props.image)})`,
                backgroundPosition: "center",
                backgroundColor: "white",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }} className="reviewerCard cShadowSmall  ">
            
            </div>
            <div style={{marginLeft: "65px", marginTop: "28px", width:"220px", borderRadius: "10px"}} className="cShadowSmall ps-2 bg-white position-absolute">
              <Ctext fontSize="15px" className="popin mt-2 ms-4" text={props.name} />
            </div>
          </div>
    </div>
    
  );
}
