import React, { useState, useEffect } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.css";
import TopImageSlider from "../components/cswiper";
import Heading from "../components/heading";
import CButton from "../components/cButtons";
import { Icon } from '@iconify/react';
import Ctext from "../components/text";
import Principle from "../components/principles";
import TestmonialSlider from "../components/testmonialSlider";
import TestmonialCard from "../components/testimonialCard";
import ContactUs from "../components/contact";
import Footer from "../components/footer";
import { Link, useLocation } from "react-router-dom";
import { useRef } from 'react';
import 'animate.css/animate.min.css'; // Import animate.css
import Activities from "../components/activities";
import CLink from "../components/cLink";

export default function HomePage(props) {
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const path = location.pathname;
    const contactFormRef = useRef(null);

    useEffect(() => {
        console.log("this is the scroll", props.scroll)
        setIsLoading(false);
        // handleScroll();
        // if (path === "contact" && contactFormRef.current) {
        //     contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
        // }
        // if (path === "/contact" && contactFormRef.current) {
        //     contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
        //   }
    }, []);
    if (path === "contact" && contactFormRef.current) {
        contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (props.scroll === "true" && contactFormRef.current) {
        contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    } 

    function scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    }
  
  return !isLoading ? (
    <>
        <div>
            <TopImageSlider/>
            {/* <Heading  className="position-absolute top-0"  fontFamily="Abril Fatface" text="Explore the World the Halal way!" /> */}
        </div>
        {/* <div className="position-absolute d-flex justify-content-center w-100" style={{marginTop: "-80px"}}>
            <div className="row  w-100">
                <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end align-items-center">
                    <div>
                        <Heading className="tourText abril" fontFamily="Abril Fatface" text="Upcoming Tour" />
                    </div>
                </div>
                <div className="col-12 col-lg-7">
                    <a href="tour_details/1" className="tourLink text-decoration-none">
                        <div className="tourImageContainer ">
                            <div className="tourImage bgMont cShadowSmall">
                                {/* <CButton padding="10px 20px" className="position-absolute top-0 right-0 mt-2 text-white bg-theme mont" fontWeight="bold" text="UGX 200,000" /> */}
                            {/* </div>
                            <div className="zoom tourDetailsCard mx-auto mx-lg-0 bg-white cShadow p-3">
                                <Ctext className="mont" fontWeight="bold" fontSize="20px" text="Mt Elgon National Game park" />
                                <div className="d-flex align-items-center">
                                    <Icon className="me-1" fontSize="20px" color="#F3B324" icon="icomoon-free:price-tags" />
                                    <Ctext fontSize="16px" fontWeight="bolder" className="mont mt-2" text="UGX 200,000" />
                                </div>
                                <div className="d-flex align-items-center">
                                    <Icon className="me-1" fontSize="20px" color="#F3B324" icon="mdi:location" />
                                    <Ctext fontSize="16px" className="mont mt-2" text="Western uganda" />
                                </div>
                                <div className="light-bg d-flex justify-content-between px-2" style={{borderRadius: "8px"}}>
                                    <div className="d-flex align-items-center">
                                        <Icon className="me-1" fontSize="20px" color="#F3B324" icon="uil:calender" />
                                        <Ctext fontSize="15px" color="#757783" fontFamily="DM sans" className="mt-2" text="24/04/2024" />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Icon className="me-1" fontSize="20px" color="#F3B324" icon="mingcute:time-line" />
                                        <Ctext fontSize="15px" color="#757783" fontFamily="DM sans" className="mt-2" text="10 days left" />
                                    </div>
                                </div>
                                <CButton padding="10px 20px" className="w-100 mt-2 text-white bg-theme mont" fontWeight="bold" text="View all Details" />
                            </div> */}
                        {/* </div>
                    </a>
                </div>
            </div>
        </div> */}

        <div className="mtSketch w-100" style={{backgroundColor: "#F0F3F6", paddingBottom: "150px"}}>
            <div className="row w-100 m-0">
                <div className="col-12 col-lg-6 mb-3 mb-lg-0 d-flex justify-content-center justify-content-md-end">
                    <div className="w-100 d-flex justify-content-center  p-0">
                        <div className="shake aboutBox2 bgWaterfall cShadow position-absolute bg-white p-3">
                        </div>
                        <div className="shake3 aboutCard bgElephant cShadow">
                        </div>
                        <div className="shake2 aboutBox1 bgSunset cShadow position-absolute bg-white p-3">
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mt-5 mt-lg-0 ps-4 ps-md-5 pt-3 pt-md-5">
                    <div className="w-100">
                        <Heading className="popin" fontFamily="Abril Fatface" text="ABOUT US" />
                        <Ctext fontSize="15px" className="mont me-lg-5 mt-2" text="Welcome to Al Atharee Tours, your gateway to exceptional halal travel experiences. We are dedicated to providing unforgettable journeys that seamlessly blend cultural enrichment, faith-based exploration, and the beauty of the world. Discover destinations where traditions meet modernity, and spirituality mingles with adventure. Join us for a unique travel experience rooted in faith and tailored to your desires." />
                    </div>
                    <Principle circleWidth="50px" height= "30px" width= "30px" image="safety.png" title="Safety first always" text="Embark on your adventure with peace of mind, as we prioritize your safety at every step of the journey." />
                    <Principle circleWidth="50px"  height= "15px" width= "30px" image="halal.png" title="Faith-Centered" text="Experience the world while staying true to your values with our carefully curated tours that cater to your Halal needs." />
                    <Principle circleWidth="50px"  height= "27px" width= "30px" image="low_price.png" title="Excellence" text="We are committed to delivering the highest quality in every aspect of our services." />
                    <Link
                        style={{ textDecoration: "none", fontSize: "17px" }}
                        to="/about"
                        onClick={props.handleOclick}
                        className={"cNavLinkSmall "+props.linkClassName}
                    >
                        <CButton padding="10px 20px" className="mt-4 text-white bg-theme2 mont" fontWeight="bold" text="Learn More" />
                    </Link>
                </div>
            </div>
        </div>

        

        {/* <div className="container-fluid bg-theme" style={{height: "300px"}}>
            <div className="row py-5 w-100 h-100 m-0">
                <div className="col-12 col-md-8 d-flex justify-content-center align-items-center">
                    <div>
                        <Ctext fontSize="25px" color="#FFFFFF" fontFamily="DM sans" className="mt-1" text="Its only here where faith and exploration Unites" />
                        <Ctext fontSize="30px" fontWeight="bold" color="#FFFFFF" fontFamily="DM sans" className="mt-2" text="Ready for an unforgetable tour?" />
                    </div>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-start align-items-center">
                  <CButton padding="10px 20px" className="mt-2 text-white bg-theme2 mont" fontWeight="bold" text="Book Your Spot Now" />
                </div>
            </div>
        </div> */}

        <Activities/>

        <div className="pt-5" style={{backgroundColor: "#FFF0C1"}}>
            <div className="container px-5">
                <Heading className="popin" text="Our Testimonials" />
                <Ctext fontSize="17px" fontWeight="bold" color="grey" className="mont mt-2" text="Have a look at what our clients have to say about us" />
            </div>
            <div className="container">
                <TestmonialSlider />
            </div>
        </div>

        <div className="container-fluid memberBg">
            <div className="row py-5 w-100">
                <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-center">
                    <div className="">
                        <Heading  className="memberText abril text-white" fontFamily="Abril Fatface" text="Not a member yet?" />
                        <Heading  className="memberText abril text-white" fontFamily="Abril Fatface" text="Join us today!" />
                        <CLink 
                            link="https://chat.whatsapp.com/DsrODzGFvVbFPMLsJZ4hEV"
                            redirect={true}
                            element={<CButton padding="10px 20px" className="mt-2 text-white bg-theme mont" fontWeight="bold" text="Join our Whatsapp group" />}
                        />
                    </div>
                </div>
                <div className="col-12 mt-4 mt-lg-0 col-lg-6 d-flex justify-content-center justify-content-lg-start align-items-center">
                    <img
                        style={{ height: "200px", width: "230px" }}
                        src={require("../images/bus.png")}
                    />
                </div>
            </div>
        </div>

        <div ref={contactFormRef} id="contactForm" className="container d-flex justify-content-center my-5">
            <div className="row">
                <div className="col-12 col-md-5 d-flex justify-content-start justify-content-md-end">
                    <div>
                        <Heading fontSize="50px" className="abril" fontFamily="Abril Fatface" text="Any Question?" />
                        <Heading fontSize="50px" className="abril" fontFamily="Abril Fatface" text="Feel Free to" />
                        <Heading fontSize="50px" className="abril" fontFamily="Abril Fatface" text="Contact us." />
                        <Ctext fontSize="15px" color="#757783" className="mont mt-2" text="Our team is always available to" />
                        <Ctext fontSize="15px" color="#757783" className="mont mt-2" text="answer any question you may have." />
                        <div className="d-flex">
                            <CLink 
                                link="https://www.facebook.com/profile.php?id=100042811433198&mibextid=ZbWKwL"
                                redirect={false}
                                element={<div className="socialIcon">
                                            <Icon icon="ic:round-facebook" color="#313041" fontSize={20} />
                                        </div>}
                            />
                            <CLink 
                                link="https://twitter.com/Alathareetours"
                                redirect={true}
                                element={<div className="socialIcon">
                                            <Icon icon="basil:twitter-outline" color="#313041" fontSize={20} />
                                        </div>}
                            />
                            <CLink 
                                link="https://instagram.com/al_atharee_tours_official?igshid=OGQ5ZDc2ODk2ZA=="
                                redirect={true}
                                element={<div className="socialIcon">
                                            <Icon icon="uil:instagram" color="#313041" fontSize={20} />
                                        </div>}
                            />
                            <CLink 
                                link="https://www.linkedin.com/company/al-atharee-tours/"
                                redirect={true}
                                element={<div className="socialIcon">
                                            <Icon icon="jam:linkedin-square" color="#313041" fontSize={20} />
                                        </div>}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-7">
                    <ContactUs/>
                </div>
            </div>
        </div>

        
        <div  className="container mt-3 mb-5">
            <div className="row d-flex justify-content-center">
                {/* <div className="col-12 col-md-3 mt-3 mt-md-0">
                    <div className="contactInfo cShadowSmall d-flex align-items-center">
                        <div className="socialIcon">
                            <Icon icon="mdi:location" color="#313041" fontSize={20} />
                        </div>
                        <div>
                            <Ctext fontSize="17px" color="#313041" className="popin mt-2" text="Address" />
                            <Ctext fontSize="16px" color="#757783" className="mont mt-1" text="Address" />
                        </div>
                    </div>
                </div> */}
                <div className="col-12 col-md-3 mt-3 mt-md-0">
                    <CLink 
                        link={`tel:+256708857976`}
                        redirect={false}
                        element={<div className="contactInfo cShadowSmall d-flex align-items-center">
                                    <div className="socialIcon">
                                        <Icon icon="solar:phone-broken" color="#313041" fontSize={20} />
                                    </div>
                                    <div>
                                        <Ctext fontSize="17px" color="#313041" className="popin mt-2" text="Phone" />
                                        <Ctext fontSize="15px" color="#757783" className="mont mt-1" text="+256708857976" />
                                    </div>
                                </div>}
                    />
                </div>
                <div className="col-12 col-md-3 mt-3 mt-md-0">
                    <CLink 
                        link={`mailto:alathareetours@gmail.com`}
                        redirect={false}
                        element={<div className="contactInfo cShadowSmall d-flex align-items-center">
                                    <div className="socialIcon">
                                        <Icon icon="ri:at-line" color="#313041" fontSize={20} />
                                    </div>
                                    <div className="bg-white">
                                        <Ctext fontSize="17px" color="#313041"  className="popin mt-2" text="Email" />
                                        <Ctext fontSize="16px" color="#757783" className="mont mt-1" text="alathareetours@gmail.com" />
                                    </div>
                                </div>}
                    />
                </div>
            </div>
        </div>
        
        <Footer/>
    </>
  ) : (
    <div className="divLoader">
      <div
        style={{ marginBottom: "200px", marginTop: "100px" }}
        className="lds-ripple"
      >
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
