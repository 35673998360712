import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Icon } from '@iconify/react';
import Ctext from "../components/text";

export default function TeamCard(props) {
  return (
    <div className="teamCard col-12 col-md-6 col-lg-3 d-flex justify-content-center">
            <div className="teamCardImage" style={{height: "350px", width: "100%", borderRadius: "8px",
                backgroundImage: `url(${require(`../images/`+props.image)})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",}}
                >
                {/* <img 
                    style={{ height: "auto", width: "100%", borderRadius: "8px" }}
                    src={require(`../images/`+props.image)}
                /> */}
            </div>
        <div className="teamLabel bg-white cShadowSmall py-2 position-absolute" style={{borderRadius: "8px"}}>
            <Ctext fontSize="17px" color="#757783" className="popin mt-1" text={props.name} />
            <Ctext fontSize="15px" fontWeight="bolder" color="#E8604C" className="mont mt-1" text={props.role} />
        </div>
    </div>
  );
}
