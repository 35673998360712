import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Icon } from '@iconify/react';
import Ctext from "../components/text";


export default function Principle(props) {
  return (
    <div className="row d-flex align-items-center">
        <div className="col-1 col-sm-1 col-md-1 col-lg-1">
          <Icon fontSize="30px" color="#F7931E" icon="ic:baseline-verified" />
        </div>
        <div className="col-3 col-sm-1 col-md-1 col-lg-1">
          <div className=" mx-3 d-flex justify-content-center align-items-center bg-white cShadow" style={{borderRadius: "40px", height: "50px", width: props.circleWidth}}>
              <img
                  style={{ height: props.height, width: props.width }}
                  src={require(`../images/${props.image}`)}
              />
          </div>
        </div>
        <div className="col-8 col-sm-8 col-md-8 col-lg-7 ps-0 ps-sm-5">
          <div>
              <Ctext fontSize="17px" className="popin mt-2" text={props.title} />
              <Ctext fontSize="15px" className="mont mt-2" text={props.text} />
          </div>
        </div>
        
    </div>
  );
}
