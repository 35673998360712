import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Icon } from '@iconify/react';
import Ctext from "../components/text";

export default function Stat(props) {
  return (
    <div className="col-12 col-md-3 mb-5 mb-md-0">
        <div className="text-center">
            <Ctext fontSize="20px" color="#E8604C" className="popin mt-2" text={props.value} />
            <Ctext fontSize="15px" color="#757783" className="mont mt-1" text={props.name} />
        </div>
    </div>
  );
}
