import { Link } from "react-router-dom";

export default function NavLink(props) {
  return (
    <li className={"px-5 mont"} key={props.keyValue}>
      <Link
        style={{ textDecoration: "none", fontSize: "17px" }}
        to={props.link === "/" ? "/" : "/" + props.link}
        onClick={props.handleOclick}
        className={"cNavLinkSmall "+props.linkClassName}
      >
        {props.name}
      </Link>
      <div style={{height: "20px", width: "40px"}} className={"cNavLinkSmall mx-auto " + props.className}></div>
    </li>
  );
}
