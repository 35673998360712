import { useState } from "react";
import CButton from "../components/cButtons";

export default function ContactUs() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSendEmail = (e) => {
        e.preventDefault(); // Prevents the form from submitting by default

        const { firstName, lastName, phoneNumber, email, message } = formData;

        // Check if any of the required fields is empty
        if (!firstName || !lastName || !phoneNumber || !email || !message) {
            // Form is invalid
            return;
        }

        const recipientEmail = 'alathareetours@gmail.com'; // Replace with the recipient's email address
        const subject = 'Al-ATHAREE INQUIRY';
        const body = `Name: ${firstName} ${lastName}\nPhone Number: ${phoneNumber}\n\nMessage: ${message}`;

        const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        // Open the user's default email client with the pre-filled email
        window.location.href = mailtoLink;
    };

    return (
        <form onSubmit={handleSendEmail}>
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="py-1">
                        <input
                            className="cField w-100"
                            type="text"
                            required
                            placeholder="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="py-1">
                        <input
                            className="cField w-100"
                            type="text"
                            required
                            placeholder="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="py-1">
                        <input
                            className="cField w-100"
                            type="tel"
                            required
                            placeholder="Phone Number"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="py-1">
                        <input
                            className="cField w-100"
                            type="email"
                            required
                            placeholder="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <textarea
                        style={{ height: "200px", color: "#757783" }}
                        required
                        className="mx-0 mt-1 cField w-100"
                        placeholder="Your Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>
                    <CButton
                        type="submit"
                        padding="10px 20px"
                        className="mt-3 text-white bg-theme mont"
                        fontWeight="bold"
                        text="Send Message"
                    />
                </div>
            </div>
        </form>
    );
}
