import Heading from "../components/heading";
import "bootstrap/dist/css/bootstrap.css";
import 'animate.css/animate.min.css'; // Import animate.css
import React, { useEffect } from 'react';
import CButton from "./cButtons";
import { Icon } from '@iconify/react';

export default function TopImageSlider() {

  

  return (
    <div id="carouselTop" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active ">
              <div className="d-flex justify-content-start justify-content-md-start align-items-center" style={{height: "70vh", width: "100%", 
                  backgroundImage: `url(${require("../images/new/image16.jpeg")})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  backgroundBlendMode: "overlay",
              }}>
              <div className="box-1 animate__animated animate__slideInDown position-absolute" style={{zIndex: 0, height: "100%", width: "20%", backgroundColor: "rgba(0,0,0,0.3)"}}></div>
              <div className="box-3 animate__animated animate__slideInDown position-absolute" style={{marginLeft: "40%", zIndex: 0, height: "100%", width: "20%", backgroundColor: "rgba(0,0,0,0.3)"}}></div>
              <div className="box-5 animate__animated animate__slideInDown position-absolute" style={{marginLeft: "80%", zIndex: 0, height: "100%", width: "20%", backgroundColor: "rgba(0,0,0,0.3)"}}></div>
                <div className="text-center text-md-start">
                  <Heading  color="white" className="animate__animated animate__bounceInLeft  topText text-center text-md-start abril animated-heading"  fontFamily="Abril Fatface" text="Explore the World the Halal way!" />
                  {/* <a href="tour_details/1">
                    <CButton borderRadius="0px" padding="5px 20px" icon={<Icon className="ms-2" icon="lucide:arrow-right-circle" />} className="animate__animated animate__zoomInDown topBtn text-white bg-theme mont py-2" fontWeight="bold" text="BOOK TOUR NOW" />
                  </a> */}
                  {/* <Heading fontSize="50px" color="white" className="text-center abril ms-5"  fontFamily="Abril Fatface" text="Halal way!" /> */}
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div className="d-flex justify-content-start justify-content-md-start align-items-center" style={{height: "70vh", width: "100%", 
                  backgroundImage: `url(${require("../images/new/img10.jpeg")})`, // Use require to get the correct path
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  backgroundBlendMode: "overlay",
              }}>
                <div className="box-1 animate__animated animate__slideInUp position-absolute" style={{zIndex: 0, height: "100%", width: "20%", backgroundColor: "rgba(0,0,0,0.3)"}}></div>
                <div className="box-3 animate__animated animate__slideInUp position-absolute" style={{marginLeft: "40%", zIndex: 0, height: "100%", width: "20%", backgroundColor: "rgba(0,0,0,0.3)"}}></div>
                <div className="box-5 animate__animated animate__slideInUp position-absolute" style={{marginLeft: "80%", zIndex: 0, height: "100%", width: "20%", backgroundColor: "rgba(0,0,0,0.3)"}}></div>
                <div className="text-center text-md-start">
                  <Heading  color="white" className="animate__animated animate__bounceInRight topText text-center text-md-start abril animated-heading"  fontFamily="Abril Fatface" text="Traveling with Respect, Exploring with Purpose" />
                  {/* <CButton borderRadius="0px" padding="5px 20px" icon={<Icon className="ms-2" icon="lucide:arrow-right-circle" />} className="animate__animated animate__zoomInUp topBtn text-white bg-theme mont py-2" fontWeight="bold" text="ABOUT US" /> */}
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div className="d-flex justify-content-start justify-content-md-start align-items-center" style={{height: "70vh", width: "100%", 
                  backgroundImage: `url(${require("../images/new/image3.jpeg")})`, // Use require to get the correct path
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  backgroundBlendMode: "overlay",
              }}>
                <div className="box-1 animate__animated animate__zoomIn position-absolute" style={{zIndex: 0, height: "100%", width: "20%", backgroundColor: "rgba(0,0,0,0.3)"}}></div>
                <div className="box-3 animate__animated animate__zoomIn position-absolute" style={{marginLeft: "40%", zIndex: 0, height: "100%", width: "20%", backgroundColor: "rgba(0,0,0,0.3)"}}></div>
                <div className="box-5 animate__animated animate__zoomIn position-absolute" style={{marginLeft: "80%", zIndex: 0, height: "100%", width: "20%", backgroundColor: "rgba(0,0,0,0.3)"}}></div>
                <div className="text-center text-md-start">
                  <Heading  color="white" className="animate__animated animate__bounceInDown topText text-center text-md-start abril animated-heading"  fontFamily="Abril Fatface" text="Experience the Beauty of Diversity" />
                  {/* <CButton borderRadius="0px" padding="5px 20px" icon={<Icon className="ms-2" icon="lucide:arrow-right-circle" />} className="animate__animated animate__backInDown topBtn text-white bg-theme mont py-2" fontWeight="bold" text="CONTACT US" /> */}
                </div>
              </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselTop" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselTop" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
  );
}