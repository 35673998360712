import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

export default function Ctext(props) {
  return (
    <h5
      style={{
        color: props.color ? props.color : "black",
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        fontFamily: props.fontFamily,
        fontStyle:props.fontStyle,
      }}
      className={props.className}
    >
      {props.text}
    </h5>
  );
}
