import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Icon } from '@iconify/react';
import Ctext from "../components/text";


export default function Principle2(props) {
  return (
    <div className="mt-3 mt-md-0 row d-flex align-items-center">
        <div className="col-2 col-sm-1 col-md-1 col-lg-1">
          <Icon fontSize="35px" color="white"  icon="solar:star-bold-duotone" />
        </div>
        <div className="col-8 col-lg-7">
          <div>
              <Ctext fontSize="17px" className="text-white popin mt-2" text={props.title} />
              <Ctext fontSize="15px" className="text-white mont mt-2" text={props.text} />
          </div>
        </div>
        
    </div>
  );
}
