import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.css";
import Ctext from "../components/text";
import Principle from "../components/principles";
import Heading from "../components/heading";
import CButton from "../components/cButtons";
import Footer from "../components/footer";
import Stat from "../components/stat";
import TeamCard from "../components/teamCard";
import Principle2 from "../components/Principle2";
import { Icon } from '@iconify/react';
import ContactUs from "../components/contact";
import CLink from "../components/cLink";
import { Link, useLocation } from "react-router-dom";

export default function AboutPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const contactFormRef = useRef(null);
  const location = useLocation();
  const path = location.pathname;
  
  useEffect(() => {
      console.log("this is the scroll", props.scroll)
      setIsLoading(false);
      // handleScroll();
      // if (path === "contact" && contactFormRef.current) {
      //     contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
      // }
      // if (path === "/contact" && contactFormRef.current) {
      //     contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
      //   }
  }, []);
  if (path === "contact" && contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  if (props.scroll === "true" && contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  } 

  function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
  }
  
  return !isLoading ? (
    <>
        <div className="container-fluid aboutBg" style={{height: "400px"}}>
            <div className="container d-flex align-items-center h-100">
              <Heading className="abril" color="white" fontFamily="Abril Fatface" text="ABOUT US" />
            </div>
        </div>

        <div className="container-fluid p-0">
          <div className="row w-100 m-0">
            <div className="col-12 col-lg-6 py-5" style={{backgroundColor: "#FFF0C1", height: "auto"}}>
              <div className="pb-5 d-flex justify-content-start justify-content-md-center">
                <div className="aboutImage" >
                  <div className="zoom2" style={{boxShadow: "2px 10px 34px -7px rgba(0,0,0,0.9)", backgroundColor: "#F3B324", height: "600px"}}>

                  </div>
                  <div className="zoom cShadowBig bgAbount" style={{boxShadow: "2px 10px 34px -7px rgba(0,0,0,0.9)", height: "600px", position: "absolute", marginTop: "-550px"}}>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 py-5" style={{backgroundColor: "#E8604C"}}>
              <div className="w-100 px-3 px-md-5">
                  <Heading fontSize="25px" className="popin" color="#F3B324" text="OUR MISSION" />
                  <Ctext fontSize="17px"  className="mb-4 text-white mont mt-2" text="To facilitate meaningful and memorable journeys that nourish the spirit, enrich cultural understanding, and foster connections with the world's diverse heritage. We strive to provide meticulously crafted halal tour experiences that leave a lasting positive impact on our tourists and the communities we engage with." />
                  <Heading fontSize="25px" className="popin" color="#F3B324" text="OUR VISION" />
                  <Ctext fontSize="17px"  className="mb-4 text-white mont mt-2" text="To be the premier provider of halal tour experiences, recognized for our commitment to excellence, cultural immersion, and spiritual enrichment. We envision a world where individuals of all backgrounds can explore the beauty and diversity of the globe while upholding their faith and values" />
                  <Heading fontSize="25px" className="popin" color="#F3B324" text="OUR CORE VALUES" />
                  <Principle2 circleWidth="50px" height= "30px" width= "30px" title="Safety first always" text="Embark on your adventure with peace of mind, as we prioritize your safety at every step of the journey." />
                  <Principle2 circleWidth="50px"  height= "15px" width= "30px" title="Faith-Centered" text="We are guided by our faith, ensuring that all our tour experiences respect and uphold Islamic principles." />
                  <Principle2 circleWidth="50px"  height= "27px" width= "30px" title="Excellence" text="We are committed to delivering the highest quality in every aspect of our services." /> 
              </div>           
            </div>
          </div>
        </div>


        <div className="container-fluid aboutBg2 d-flex justify-content-center align-items-center" style={{height: "400px"}}>
          <div className="text-center">
            <img
                style={{ width: "auto", height: "100px" }}
                src={require("../images/halal_image.png")}
            />
            <Ctext fontSize="30px" color="#FFFFFF" className="popin mt-3" text="We Unveil the World the Halal Way" />
          </div>
        </div>
        
        <div className="container">
          <div className="row bg-white cShadowSmall mx-5 py-4" style={{marginTop: "-40px", borderRadius: "8px"}}>
            <Stat name="Charity out-reaches" value="None Yet" />
            <Stat name="Trips made" value="1" />
            <Stat name="Happy Tourists" value="20" />
            <Stat name="Our member" value="31" />
          </div>
        </div>
        
        <div className="container text-center" style={{marginTop: "50px"}}>
          <Heading className="popin mb-4"  text="MEET OUR TEAM" />
          <div className="row d-flex justify-content-center">
            <TeamCard image="zaharah.png" name="Zaharah Bidin" role="CORDINATOR" />
            <TeamCard image="kasooha.jpeg" name="Kasooha Ahamadah Amooti" role="CORDINATOR" />
            <TeamCard image="ali.jpeg" name="Ssenabulya Ali" role="TREASURER" />
            <TeamCard image="wabwe.jpeg" name="shadick Wabwe" role="MARKETING" />
            <TeamCard image="habibah.jpeg" name="Namiiro Habiibah" role="MARKETING" />
            <TeamCard image="ashraf.jpg" name="Ashraf Nyanzi" role="IT MANAGER" />
            <TeamCard image="ratif.png" name="Ratif Ssemujju" role="GRAPHICS DESIGNER" />
          </div>
        </div>

        <div id="contactForm" className="container d-flex justify-content-center my-5">
            <div className="row">
                <div className="col-12 col-md-5 d-flex justify-content-start justify-content-md-end">
                    <div>
                        <Heading fontSize="50px" className="abril" fontFamily="Abril Fatface" text="Any Question?" />
                        <Heading fontSize="50px" className="abril" fontFamily="Abril Fatface" text="Feel Free to" />
                        <Heading fontSize="50px" className="abril" fontFamily="Abril Fatface" text="Contact us." />
                        <Ctext fontSize="15px" color="#757783" className="mont mt-2" text="Our team is always available to" />
                        <Ctext fontSize="15px" color="#757783" className="mont mt-2" text="answer any question you may have." />
                        <div className="d-flex">
                            <CLink 
                                link="https://www.facebook.com/profile.php?id=100042811433198&mibextid=ZbWKwL"
                                redirect={false}
                                element={<div className="socialIcon">
                                            <Icon icon="ic:round-facebook" color="#313041" fontSize={20} />
                                        </div>}
                            />
                            <CLink 
                                link="https://twitter.com/Alathareetours"
                                redirect={true}
                                element={<div className="socialIcon">
                                            <Icon icon="basil:twitter-outline" color="#313041" fontSize={20} />
                                        </div>}
                            />
                            <CLink 
                                link="https://instagram.com/al_atharee_tours_official?igshid=OGQ5ZDc2ODk2ZA=="
                                redirect={true}
                                element={<div className="socialIcon">
                                            <Icon icon="uil:instagram" color="#313041" fontSize={20} />
                                        </div>}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-7">
                    <ContactUs/>
                </div>
            </div>
        </div>

        <Footer/>
    </>
  ) : (
    <div className="divLoader">
      <div
        style={{ marginBottom: "200px", marginTop: "100px" }}
        className="lds-ripple"
      >
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
