
import Ctext from "../components/text";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Icon } from '@iconify/react';
import CLink from "./cLink";

export default function Footer() {
  return (
    <div className="">
        <div className="d-flex justify-content-center align-items-center" style={{height: "200px", backgroundColor: "#313041"}}>
            <div className="text-center">
                <div className="bg-white px-2 py-1" style={{ borderRadius: "10px" }}>
                    <img
                        style={{ width: "auto", height: "50px" }}
                        src={require("../images/logo.png")}
                        alt=""
                    />
                </div>
                <div className="d-flex justify-content-center mt-3">
                    <CLink 
                        link={`tel:+256781862914`}
                        redirect={true}
                        element={<Icon icon="ic:round-facebook" color="white" fontSize={30} />}
                    />
                    <CLink 
                        link="https://twitter.com/Alathareetours"
                        redirect={true}
                        element={<Icon icon="basil:twitter-outline" color="white" className="mx-2" fontSize={30} />}
                    />
                    <CLink 
                        link="https://instagram.com/al_atharee_tours_official?igshid=OGQ5ZDc2ODk2ZA=="
                        redirect={true}
                        element={<Icon icon="uil:instagram" color="white" fontSize={30} />}
                    />
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-center" style={{height: "40px", backgroundColor: "#191921"}}>
            <div className="d-flex align-items-center">
                <Icon className="me-1" icon="mdi:copyright" color="white" fontSize={20} />
                <Ctext fontStyle="italic" fontSize="15px" color="#D9D9D9" fontFamily="DM sans" className="mt-2" text="alathareetours" />
            </div>
        </div>
    </div>
  );
}
