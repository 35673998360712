import React, { Suspense } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import HomePage from "./pages/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TopLabel from "./components/top_label";
import CNavBar from "./components/cNavbar";
import AboutPage from "./pages/about";
import TourDetails from "./components/tourDetails";



const loading = (
  <div
    className="w-100 d-flex justify-content-center align-items-center"
    style={{ height: "100vh" }}
  >
    <h1 className="">loading....</h1>
  </div>
);

export default function App(props) {
  
  return (
      <BrowserRouter>
        <CNavBar/>
        {/* <TopLabel/> */}
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage scroll="true"/>} />
            {/* <Route path="/contact" element={<HomePage scroll="true"/>} /> */}
            <Route path="/tour_details/:tour_id" element={<TourDetails />} />
            {/* <Route path="/cart" element={<CartPage />} /> */}
          </Routes>
        </Suspense>
      </BrowserRouter>
  );
}
