import Heading from "../components/heading";
import "bootstrap/dist/css/bootstrap.css";
import 'animate.css/animate.min.css'; // Import animate.css
import React, { useState, useEffect, useContext } from "react";
import CButton from "./cButtons";
import { Icon } from '@iconify/react';
import ImageCard from "../components/imageCard";
import Activity from "../components/activity";
import 'animate.css/animate.min.css'; // Import animate.css
import Ctext from "../components/text";

export default function Activities() {
    const [active_link, setActiveLinks] = useState(1);
    const [images, setImages] = useState([]);
    const [fadeOut, setFadeOut] = useState(false);

    const allImages = [
        {id:1, image: require("../images/new/img4.jpeg")},
        {id:1, image: require("../images/new/img5.jpeg")},
        {id:1, image: require("../images/new/img6.jpeg")},
        {id:1, image: require("../images/new/img7.jpeg")},
        {id:1, image: require("../images/new/img8.jpeg")},
        {id:1, image: require("../images/new/img9.jpeg")},
        {id:1, image: require("../images/new/img10.jpeg")},
        {id:1, image: require("../images/new/img13.jpeg")},
        {id:1, image: require("../images/new/img11.jpeg")},
    ]

    const image1 = [
        {id:1, image: require("../images/new/img4.jpeg")},
        {id:1, image: require("../images/new/img5.jpeg")},
        {id:1, image: require("../images/new/img6.jpeg")},
    ]
    
    const image2 = [
        {id:1, image: require("../images/new/img7.jpeg")},
        {id:1, image: require("../images/new/img8.jpeg")},
        {id:1, image: require("../images/new/img9.jpeg")},
    ]
    
    const image3 = [
        {id:1, image: require("../images/new/img10.jpeg")},
        {id:1, image: require("../images/new/img13.jpeg")},
        {id:1, image: require("../images/new/img11.jpeg")},
    ]




    const image4 = [
        {id:1, image: require("../images/new/img4.jpeg")},
        {id:1, image: require("../images/new/img5.jpeg")},
    ]
    
    const image5 = [
        {id:1, image: require("../images/new/img7.jpeg")},
        {id:1, image: require("../images/new/img8.jpeg")},
    ]
    
    const image6 = [
        {id:1, image: require("../images/new/img10.jpeg")},
        {id:1, image: require("../images/new/img13.jpeg")},
    ]

    const image7 = [
        {id:1, image: require("../images/new/img6.jpeg")},
        {id:1, image: require("../images/new/img9.jpeg")},
    ]



    
  

  return (
    <>
        <div className="carousel-big" style={{ backgroundColor: "black"}}>
            <div className="row w-100 h-100 m-0">
                <div className="bgActivitiesSmall col-12 col-lg-5 bgActivities d-flex justify-content-center align-items-center">
                    <div className="text-center px-4 py-2 w-75">
                        {/* <Ctext fontSize="25px" color="black" className="popin mt-2" text="Recent Trip" /> */}
                        <Ctext fontSize="23px" fontWeight="bolder" color="white" className="popin mt-2" text="Relive our memorable journey to Jinja, where nature and adventure combined to create lasting memories!" />
                    </div>
                </div>
                <div className="col-12 col-lg-7 ">
                    <div id="imageSlider1" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner" style={{height: "550px"}}>
                            <div class="carousel-item  active " style={{height: "550px"}}>
                                <div className="row h-100 d-flex align-items-center justify-content-center">
                                    {image1.map((image, index)=>{
                                        return (
                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <div className="animate__animated animate__jackInTheBox" key={image.id}>
                                                    {/* <div data-bs-toggle="modal" data-bs-target={"#image"+image.id} style={{height: "450px",  */}
                                                    <div style={{height: "450px", 
                                                        backgroundImage: `url(${image.image})`,
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        // backgroundColor: "rgba(0,0,0,0.5)",
                                                        // backgroundBlendMode: "overlay",
                                                        borderRadius: "8px",
                                                    }}>
                                                </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div class="carousel-item " style={{height: "550px"}}>
                                <div className="row h-100 d-flex align-items-center justify-content-center">
                                    {image2.map((image, index)=>{
                                        return (
                                            <div className="col-12 col-md-4">
                                                <div className="animate__animated animate__jackInTheBox" key={image.id}>
                                                    <div style={{height: "450px", 
                                                        backgroundImage: `url(${image.image})`,
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        // backgroundColor: "rgba(0,0,0,0.5)",
                                                        // backgroundBlendMode: "overlay",
                                                        borderRadius: "8px",
                                                    }}>
                                                </div>
                                                </div>
                                                {/* <div class="modal fade" id={"image" + image.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" >
                                                        <div class="modal-content" style={{backgroundColor: "rgba(0,0,0,0.8)"}}>
                                                            <div class="modal-header d-flex justify-content-end p-0" style={{border: "none"}}>
                                                                <Icon icon="mdi:close" className="m-2" data-bs-dismiss="modal" aria-label="Close" color="white" fontSize={30} />
                                                            </div>
                                                            <div class="modal-body text-center p-1">
                                                                <img  src={image.image} class="img-fluid" alt="Image Preview" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div class="carousel-item  " style={{height: "550px"}}>
                                <div className="row h-100 d-flex align-items-center justify-content-center">
                                    {image3.map((image, index)=>{
                                        return (
                                            <div className="col-12 col-md-4">
                                                <div className="animate__animated animate__jackInTheBox" key={image.id}>
                                                    <div style={{height: "450px", 
                                                        backgroundImage: `url(${image.image})`,
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        // backgroundColor: "rgba(0,0,0,0.5)",
                                                        // backgroundBlendMode: "overlay",
                                                        borderRadius: "8px",
                                                    }}>
                                                </div>
                                                </div>
                                                {/* <div class="modal fade" id={"image" + image.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" >
                                                        <div class="modal-content" style={{backgroundColor: "rgba(0,0,0,0.8)"}}>
                                                            <div class="modal-header d-flex justify-content-end p-0" style={{border: "none"}}>
                                                                <Icon icon="mdi:close" className="m-2" data-bs-dismiss="modal" aria-label="Close" color="white" fontSize={30} />
                                                            </div>
                                                            <div class="modal-body text-center p-1">
                                                                <img  src={image.image} class="img-fluid" alt="Image Preview" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#imageSlider1" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#imageSlider1" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="carousel-mid" style={{ backgroundColor: "black"}}>
            <div className="row w-100 h-100 m-0">
                <div style={{height: "350px"}} className="bgActivitiesSmall col-12 col-lg-5 bgActivities d-flex justify-content-center align-items-center">
                    <div className="text-center px-4 py-2 w-75">
                        {/* <Ctext fontSize="25px" color="black" className="popin mt-2" text="Recent Trip" /> */}
                        <Ctext fontSize="23px" fontWeight="bolder" color="white" className="popin mt-2" text="Relive our memorable journey to Jinja, where nature and adventure combined to create lasting memories!" />
                    </div>
                </div>
                <div className="col-12 col-lg-7 ">
                    <div id="imageSlider2" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner" style={{height: "550px"}}>
                            <div class="carousel-item  active " style={{height: "550px"}}>
                                <div className="row h-100 d-flex align-items-center justify-content-center">
                                    {image4.map((image, index)=>{
                                        return (
                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <div className="animate__animated animate__jackInTheBox" key={image.id}>
                                                    {/* <div data-bs-toggle="modal" data-bs-target={"#image"+image.id} style={{height: "450px",  */}
                                                    <div style={{height: "450px", 
                                                        backgroundImage: `url(${image.image})`,
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        // backgroundColor: "rgba(0,0,0,0.5)",
                                                        // backgroundBlendMode: "overlay",
                                                        borderRadius: "8px",
                                                    }}>
                                                </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div class="carousel-item " style={{height: "550px"}}>
                                <div className="row h-100 d-flex align-items-center justify-content-center">
                                    {image5.map((image, index)=>{
                                        return (
                                            <div className="col-12 col-md-4">
                                                <div className="animate__animated animate__jackInTheBox" key={image.id}>
                                                    <div style={{height: "450px", 
                                                        backgroundImage: `url(${image.image})`,
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        // backgroundColor: "rgba(0,0,0,0.5)",
                                                        // backgroundBlendMode: "overlay",
                                                        borderRadius: "8px",
                                                    }}>
                                                </div>
                                                </div>
                                                {/* <div class="modal fade" id={"image" + image.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" >
                                                        <div class="modal-content" style={{backgroundColor: "rgba(0,0,0,0.8)"}}>
                                                            <div class="modal-header d-flex justify-content-end p-0" style={{border: "none"}}>
                                                                <Icon icon="mdi:close" className="m-2" data-bs-dismiss="modal" aria-label="Close" color="white" fontSize={30} />
                                                            </div>
                                                            <div class="modal-body text-center p-1">
                                                                <img  src={image.image} class="img-fluid" alt="Image Preview" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div class="carousel-item  " style={{height: "550px"}}>
                                <div className="row h-100 d-flex align-items-center justify-content-center">
                                    {image6.map((image, index)=>{
                                        return (
                                            <div className="col-12 col-md-4">
                                                <div className="animate__animated animate__jackInTheBox" key={image.id}>
                                                    <div style={{height: "450px", 
                                                        backgroundImage: `url(${image.image})`,
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        // backgroundColor: "rgba(0,0,0,0.5)",
                                                        // backgroundBlendMode: "overlay",
                                                        borderRadius: "8px",
                                                    }}>
                                                </div>
                                                </div>
                                                {/* <div class="modal fade" id={"image" + image.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" >
                                                        <div class="modal-content" style={{backgroundColor: "rgba(0,0,0,0.8)"}}>
                                                            <div class="modal-header d-flex justify-content-end p-0" style={{border: "none"}}>
                                                                <Icon icon="mdi:close" className="m-2" data-bs-dismiss="modal" aria-label="Close" color="white" fontSize={30} />
                                                            </div>
                                                            <div class="modal-body text-center p-1">
                                                                <img  src={image.image} class="img-fluid" alt="Image Preview" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div class="carousel-item  " style={{height: "550px"}}>
                                <div className="row h-100 d-flex align-items-center justify-content-center">
                                    {image7.map((image, index)=>{
                                        return (
                                            <div className="col-12 col-md-4">
                                                <div className="animate__animated animate__jackInTheBox" key={image.id}>
                                                    <div style={{height: "450px", 
                                                        backgroundImage: `url(${image.image})`,
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        // backgroundColor: "rgba(0,0,0,0.5)",
                                                        // backgroundBlendMode: "overlay",
                                                        borderRadius: "8px",
                                                    }}>
                                                </div>
                                                </div>
                                                {/* <div class="modal fade" id={"image" + image.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" >
                                                        <div class="modal-content" style={{backgroundColor: "rgba(0,0,0,0.8)"}}>
                                                            <div class="modal-header d-flex justify-content-end p-0" style={{border: "none"}}>
                                                                <Icon icon="mdi:close" className="m-2" data-bs-dismiss="modal" aria-label="Close" color="white" fontSize={30} />
                                                            </div>
                                                            <div class="modal-body text-center p-1">
                                                                <img  src={image.image} class="img-fluid" alt="Image Preview" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#imageSlider2" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#imageSlider2" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="carousel-small" style={{ backgroundColor: "black"}}>
            <div className="row w-100 h-100 m-0">
                <div style={{height: "350px"}} className="col-12 col-lg-5 bgActivities d-flex justify-content-center align-items-center">
                    <div className="text-center px-4 py-2 w-75">
                        {/* <Ctext fontSize="25px" color="black" className="popin mt-2" text="Recent Trip" /> */}
                        <Ctext fontSize="23px" fontWeight="bolder" color="white" className="popin mt-2" text="Relive our memorable journey to Jinja, where nature and adventure combined to create lasting memories!" />
                    </div>
                </div>
                <div className="carousel-small col-12 col-lg-7 ">
                    <div id="imageSlider" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="mt-2 mb-5 carousel-inner" style={{height: "550px"}}>
                            {allImages.map((image, index)=>{
                                return (
                                    <div className={`carousel-item ${(index == 1)?"active":"bro"}`}>
                                        <div className="animate__animated animate__jackInTheBox" key={image.id}>
                                            {/* <div data-bs-toggle="modal" data-bs-target={"#image"+image.id} style={{height: "450px",  */}
                                            <div style={{height: "550px", 
                                                backgroundImage: `url(${image.image})`,
                                                backgroundPosition: "center",
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                // backgroundColor: "rgba(0,0,0,0.5)",
                                                // backgroundBlendMode: "overlay",
                                                borderRadius: "8px",
                                            }}>
                                        </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#imageSlider" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#imageSlider" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

// import Heading from "../components/heading";
// import "bootstrap/dist/css/bootstrap.css";
// import 'animate.css/animate.min.css'; // Import animate.css
// import React, { useState, useEffect, useContext } from "react";
// import CButton from "./cButtons";
// import { Icon } from '@iconify/react';
// import ImageCard from "../components/imageCard";
// import Activity from "../components/activity";
// import 'animate.css/animate.min.css'; // Import animate.css

// export default function Activities() {
//     const [active_link, setActiveLinks] = useState(1);
//     const [images, setImages] = useState([]);
//     const [fadeOut, setFadeOut] = useState(false);

//     useEffect(() => {
//         setActiveLinks(1);
//         setImages([
//             {comp: <ImageCard image="mont.png"/>, image: "mont.png"},
//             {comp: <ImageCard image="gif1.jpg"/>, image: "gif1.jpg"},
//             {comp: <ImageCard image="gif2.jpg"/>, image: "gif2.jpg"},
//             {comp: <ImageCard image="gif2.jpg"/>, image: "gif2.jpg"},
//             {comp: <ImageCard image="gif2.jpg"/>, image: "gif2.jpg"},
//             {comp: <ImageCard image="gif2.jpg"/>, image: "gif2.jpg"},
//         ]);
//       }, []);

//     const activities = [
//         {   
//             id: 1,
//             name: "Dawah",
//             images: [
//                 {comp: <ImageCard image="mont.png"/>, image: "mont.png"},
//                 {comp: <ImageCard image="gif1.jpg"/>, image: "gif1.jpg"},
//                 {comp: <ImageCard image="gif2.jpg"/>, image: "gif2.jpg"},
//                 {comp: <ImageCard image="gif2.jpg"/>, image: "gif2.jpg"},
//                 {comp: <ImageCard image="gif2.jpg"/>, image: "gif2.jpg"},
//                 {comp: <ImageCard image="gif2.jpg"/>, image: "gif2.jpg"},
//             ]
//         },
//         {   
//             id: 2,
//             name: "Charity",
//             images: [
//                 {comp: <ImageCard image="sunset.jpg"/>, image: "sunset.jpg"},
//                 {comp: <ImageCard image="waterfall.jpg"/>, image: "waterfall.jpg"},
//                 {comp: <ImageCard image="zebra.jpg"/>, image: "zebra.jpg"},
//             ]
//         },
//         {   
//             id: 3,
//             name: "Exploration",
//             images: [
//                 {comp: <ImageCard image="elephant.jpg"/>, image: "elephant.jpg"},
//                 {comp: <ImageCard image="elephants.jpg"/>, image: "elephants.jpg"},
//                 {comp: <ImageCard image="gif2.jpg"/>, image: "gif2.jpg"},
//             ]
//         },
//         {   
//             id: 4,
//             name: "Visiting",
//             images: [
//                 {comp: <ImageCard image="mont.png"/>, image: "mont.png"},
//                 {comp: <ImageCard image="gif1.jpg"/>, image: "gif1.jpg"},
//                 {comp: <ImageCard image="gif2.jpg"/>, image: "gif2.jpg"},
//             ]
//         },
//     ]

//     const handleActivityClick = (activity) => {
//         setFadeOut(true);
//         setTimeout(() => {
//             setActiveLinks(activity.id);
//             setImages(activity.images);
//             setFadeOut(false);
//         }, 500); // Adjust the delay duration (in milliseconds) as needed
//     }
  

//   return (
//     <div className="activityDiv" style={{marginTop:"500px",  height: "600px"}}>
//         <div className="row w-100 h-100 m-0">
//             <div className="bgActivitiesSmall col-12 col-md-5 bgActivities d-flex justify-content-end align-items-center">
//                 <div className="activityCard cShadowSmall" style={{borderRadius: "10px"}}>
//                     <Activity className="w-100" text="Trip Activities" bgColor="white" />
//                     <div className="mt-2 px-2 pb-2 bg-white w-100" style={{borderRadius:"10px"}}>
//                         {
//                             activities.map((activity)=>{
//                                return <CButton transition="0.5s" padding="10px 20px" 
//                                className={`w-100 mt-2 mont ${(activity.id === active_link)?"text-white bg-theme2":"text-dark light-bg"}`} fontWeight="bold" text={activity.name}
//                                handleClick={() => handleActivityClick(activity)} 
//                                /> 
//                             })
//                         }
//                     </div>
//                 </div>
//             </div>
//             <div className="col-12 col-md-7 d-flex justify-content-center align-items-center">
//                 <div style={{height: "500px", overflowY: "scroll", borderRadius: "20px"}} className="mt-3 mt-md-0 row w-100 d-md-flex justify-content-center align-items-center">
                    
//                     {
//                         images.map((image, index)=>{
//                             return (
//                                 <>
//                                     <div data-bs-toggle="modal" data-bs-target={"#"+image.image} key={index} className={`col-12 col-sm-6 col-md-6 col-lg-4 animate__animated ${fadeOut ? 'animate__zoomOut' : 'animate__zoomIn'}`}>
//                                         {image.comp}
//                                     </div>
//                                     <div class="modal fade" id={image.image} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//                                         <div class="modal-dialog modal-dialog-centered" >
//                                             <div class="modal-content" style={{backgroundColor: "rgba(0,0,0,0.8)"}}>
//                                                 <div class="modal-header d-flex justify-content-end p-0" style={{border: "none"}}>
//                                                     <Icon icon="mdi:close" className="m-2" data-bs-dismiss="modal" aria-label="Close" color="white" fontSize={30} />
//                                                 </div>
//                                                 <div class="modal-body text-center p-1">
//                                                     <img  src={require("../images/"+image.image)} class="img-fluid" alt="Image Preview" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </>
//                             );
//                         })
//                     }
//                 </div>
//             </div>
//         </div>
//     </div>
//   );
// }